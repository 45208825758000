.toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    background: #242424;
    height: 56px;
    z-index: 2;
}

.hidden {
    display: none !important;
}

.toolbar_navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar_logo {
    margin-left: 1rem;
}

.toolbar_logo a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.toolbar_button {
    background: none;
    border: 0;
    padding: 0;
    font-size: 1em;
    font-family: inherit;
    color: #f90;
    margin: 0;
    width: 66px;
}

.toolbar_button:hover{
    background: none;
    border: 0;
    padding: 0;
    font-size: 1em;
    font-family: inherit;
    color: white;
    cursor: pointer;
}

.spacer {
    flex: 1;
}

.authspacer {
    padding-left: 15px;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 0.5rem;
    font-size: 1rem;
}

.toolbar_navigation-items a {
    color: #f90;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: white;

}

@media (max-width: 1219px) {
    .toolbar_navigation-items {
        display: none;
    }
}