.profile-container {
    text-align: left;
    width: 25rem;
    margin: 0 auto;
    max-width: 80%;
}

.profile-title {
    text-align: center;
    margin-bottom: 2rem;
    color: white;
}
.button-col {
    float: left;
    width: 48%;
}

.button-col:last-child {
    margin-left: 4%;
}

.button-clips {
    float: center;
    margin-top: 2.5rem;
}

.button-row:after {
    content: "";
    display: table;
    clear: both;
}

.profile-item-name {
    color: #f90;
    margin-bottom: 16px;
}

.profile-item-subname {
    margin-left: 1.5rem;
    margin-top: 0;
}

button {
    width: 172px;
    font-size: 16px;
    padding: 12px 16px;
    font: inherit;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}

.clips-button {
    background-color: #f90;
    font: inherit;
    border: 3px solid #f90;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
}

.chpw-button {
    background-color: #242424;
    font: inherit;
    color: rgb(110, 110, 110);;
    border: 3px solid rgb(110, 110, 110);;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    height: 76px;
}

.delete-button {
    background-color: #242424;
    color: #e64942;
    font: inherit;
    border: 3px solid #e64942;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    height: 76px;
}

@media (min-width: 768px) {

    .profile-container {
        margin: 3rem auto;
    }
    
}
