.auth-form {
    width: 25rem;
    max-width: 80%;
    margin: 5rem auto;
    color: white;
}

.auth-title {
    margin-bottom: 2rem;
    color: white;
}

.form-error {
    display: none;
    padding: 12px 20px;
    margin-top: 2rem;
    margin: 8px 0;
    border: 1px solid #e64942;
    border-radius: 4px;
    background-color: #121212;
    color: #ccc;
}

.form-control label,
.form-control input {
    width: 100%;
    display: block;
}

.form-control label {
    margin-bottom: 0.5rem;
}

.form-control select {
    background-color:rgba(0, 0, 0, 0.3);
    margin: 8px 0;
    outline: none;
    display: inline-block;
    border: 1px solid #121212;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ccc;
    width: 100%;
    height: 42px;
    font-size: 16pt;
    text-indent: 8px;
    border: 1px solid transparent;
}

.form-control input {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0px 16px;
    margin: 8px 0;
    outline: none;
    transition: all 0.15s ease;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ccc;
    border: 1px solid transparent;
}

.form-control textarea {
    resize: none;
    width: 100%;
    font-size: 16pt;
    background-color: rgba(0, 0, 0, 0.3);;
    padding: 8px 16px;
    margin: 8px 0;
    outline: none;
    display: inline-block;
    border: 1px solid #121212;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ccc;
    border: 1px solid transparent;

}

.form-control {
    margin-bottom: 1rem;
}

.form-actions {
    margin-top: 2rem;
}

.form-actions button {
    background-color: #f90;
    font: inherit;
    border: 3px solid #f90;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    margin-bottom: 1rem;
    cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active {
    background: rgb(170, 102, 0);
    border-color: rgb(170, 102, 0);
}

.switcher {
    color: rgb(110, 110, 110);
    border: 1px solid rgb(110, 110, 110) !important; 
    background-color: #242424 !important;
}

input,
input::-webkit-input-placeholder {
    font-size: 16pt;
    line-height: 2;
}

#password2 {
    display: none;
}

#username {
    display: none;
}