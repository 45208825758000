.upload-container {
    text-align: left;
    margin: 0 auto;
}

.upload-form {
    width: 25rem;
    max-width: 80%;
    margin: 0 auto;
}

.upload-title {
    text-align: center;
    margin-bottom: 2rem;
    color: white;
}

.upload-item-name {
    margin-bottom: 0;
}

.upload-button {
    margin-top: 1rem;
}

.attention {
    margin-bottom: 2rem;
}

.drop-area {
    border: 4px dashed #f90;
    text-align: center;
    height: 4rem;
}

.others {
    color:black
}

@media (max-width: 768px) {

    .upload-form {
        margin-top: 120px;
    }

    .selectdiv {
        position: relative;
        /*Don't really need this just for demo styling*/
        float: left;
        width: 100%;
    }

    .selectdiv:after {
        content: '>';
        font-size: 15pt;
        color: #ccc;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        right: 20px;
        top: 18px;
        padding: 0 0 2px;
        position: absolute;
        pointer-events: none;
    }
}


@media (min-width: 768px) {

    .profile-container {
        margin: 5rem auto;
    }
    
}
