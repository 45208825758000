html {
  font-size: 12pt;
}
.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: #fed8b1;
}

a:-webkit-any-link {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1b1b1b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shadow-md {
  box-shadow: 0 6px 8px hsla(0,0%,5%);
}

.shadow-sm {
  box-shadow: 0 2px 4px hsla(0,0%,.12%);
}



.clip-box {
  display: block;
  border-radius: 5px;
  background-color: #1b1b1b;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.box {
  margin-top: 90px;
}

@media (min-width: 768px) {
  .box {
    display: block;
    border-radius: 5px;
    background-color: #1b1b1b;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    width: 600px;
  }

  .shadow-lg {
    box-shadow: 0 12px 32px hsla(0,0%,.2%);
  }

  .clip_gallery {
    display: block;
    border-radius: 5px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    max-width: 1200px;
  }
}



/*
  SWEETALERT STUFF
*/

.swal-modal {
  box-shadow: 0 12px 32px hsla(0,0%,.2%);
  background-color: #1b1b1b;
}

.swal-footer {
  text-align: center; 
}

.swal-title {
  color: white;
}

.swal-text {
  color: white;
}

.swal-button--cancel {
  color: white;
  background-color: #242424;
  border: 1px solid rgb(110, 110, 110);
}

.swal-button--confirm {
  background-color: #f90;
  color: black;
}

.swal-button--danger {
  background-color: #e64942;
}

.swal-icon--success:after, .swal-icon--success:before {
  background-color: #1b1b1b;
}

.swal-icon--success__hide-corners  {
  background-color: #1b1b1b;
}


/*
  CLIP GALLERY STUFF
*/

.copy-link {
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  padding: 1px 5px;
  margin: 0 10px;
  position: relative;
  top: -2px;
  background: #748490;
  cursor: pointer;
  width: 62px;
  text-align: center;
  float: right;
  display: flex;
}

.copy-link-svg {
  color: white;
  position: relative;
  top: 1px;
  font-size: 10px;
  margin-right: 4px;
  width: 12px !important;
  height: 12px !important;
}

.feedcontainer {
  height: 100%;
}

.list-items {
  flex: 1 1;
  overflow: visible;
}

.mapIcon {
  padding: 16px;
  width: 55px;
}

.clip_gallery {
  width: 100%;
}

.clip_content {
  text-align: left;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 10px;
  background-clip: padding-box;
  position: relative;
}

div.clip_content img {
  width: 100%;
  height: auto;
  display: block;
}

div.clip_content video {
  width: 100%;
  height: auto;
}

.clip_map {
  color: black;
  text-transform: uppercase;
  border-radius: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #f90;
  padding: 4px;
  font-weight: bold;
}

.clip_tick {
  color: white;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  background: #1b1b1b;
  padding: 5px;
  font-weight: bold;
}

.clip_thumbnail {
  z-index: 0;
  position: relative;
  text-align: center;
  color: white;
}

.clip_title {
  font-size: 1rem;
  font-weight: 500;
  margin: 5px 0 5px;
}

.detail_wrapper {
  color: gray;
  text-transform: uppercase;
  float: center;
}

.detail_wrapper:after {
  clear: both;
}

.detail_wrapper:before, .detail_wrapper:after {
  content: " ";
  display: table;
}

.detail_separator {
  float: left;
  text-align: center;
  width: 10%; 
}

.clip_details {
  margin-bottom: 10px;
}

.clip_det {
  width: 30%;
}

.titleIcon {
  float: left;
  width: 10%;
}

.title:after {
  content: "";
  display: table;
  clear: both;
}

.titleText {
  text-transform: capitalize;
  margin-left: 55px;
  margin-top: 56px;
  padding-top: 24px;
  text-align: left;
  padding-left: 32px
}

@media (min-width: 768px) {
  .main {
    display: contents;
    margin: 8px;
    height: 100%;
  }

  .clip_content {
    width: 210px;
    height: auto;
    display: inline-flex;
    text-align: left;
    font-size: 14px;
  }

  div.clip_content img {
    width: 100%;
    height: auto;
    display: block;
  }

  div.clip_content video {
    width: 100%;
    height: auto;
  }

  .titleText {
    text-align: left;
    margin-left: 140px;
    margin-top: 30px;
    padding-top: 40px;
  }
  
  .titleIcon {
    float: left;
    width: 10%;
  }
  
  .title:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .title {
    margin-bottom: 20px;
  }

  .mapIcon {
    width: 100px;
  }

}

@media (min-width: 556px) and (max-width: 767px) {
  .clip_gallery {
    width: 100%;
    display: contents;
  }

  .clip_content {
    display: inline-flex;
    vertical-align: top;
    width: 47%;
    text-align: left;
  }

  .detail_wrapper {
    margin: 0;
    position: absolute;
    bottom: 0;
  }

  .titleText {
    text-align: left;
    padding-left: 32px;
  }
}
