.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button_line {
    width: 25px;
    height: 2px;
    background: #f90;
}

@media (min-width: 1220px) {
    .toggle-button {
        display: none;
    }
}