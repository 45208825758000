.admin_clip {
    border-bottom: 2px black solid;
    margin-bottom: 50px;
}

.admin_clip_content {
    display: inline-flex;
}

.admin_clip_details {
    float: right;
    text-align: left;
    width: 420px;
}

.admin_clip_thumbnail {
    float: left;
    width: 420px;
    height: 333px;
    position: relative;
    text-align: center;
    color: white;
    margin-right: 80px;
}

div.admin_clip_content img {
    width: 100%;
    height: auto;
    display: block;
}
  
div.admin_clip_content video {
    width: 100%;
    height: auto;
}

.admin_buttons {
    display: block;
    margin-bottom: 20px;
}

.admin_verify {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 80px;
    cursor: pointer;
}

.admin_decline {
    background-color: red;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}