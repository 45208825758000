.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
   
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.single_details {
    text-align: left;
}

.single_col
{
    display: inline-block;
    padding-right: 15px;
}

.single_row{
    padding-top: 15px;
    color: white;
    height: auto;
    overflow: auto;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
}

.single_separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px gray;
    opacity: 0.5;
}

.single_uploader {
    color: gray;
    margin-top: 20px;
    padding-left: 16px;
}

.single_description {
    margin-top: 40px;
    padding-top: 0;
    padding-left: 16px;
    padding-right: 26px;
    line-height: 1.5;
}