.side-drawer {
    height: 100%;
    background-color: #242424;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    text-align: left;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer_spacer {
    height: 100px;
}

.side-drawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: white;
}

.sidedrawer_title {
    margin-left: 40px;
}