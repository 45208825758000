.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
    margin: 0 auto;
    margin-top: 8px;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 2px dotted #ccc;
    border-radius: 4px;
  }
  
  .dzu-dropzoneActive {
    background-color: #ccc;
    border-color: #f90;
  }
  
  .dzu-dropzoneDisabled {
    opacity: 0.5;
  }
  
  .dzu-dropzoneDisabled *:hover {
    cursor: unset;
  }
  
  .dzu-input {
    display: none;
  }
  
  .dzu-inputLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #f90;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  
  .dzu-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 0 14px;
    min-height: 32px;
    background-color: #f90;
    color: black;
    border: none;
    font-family: 'Helvetica', sans-serif;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  
  .dzu-previewContainer {
    padding: 40px 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    min-height: 60px;
    z-index: 1;
    box-sizing: border-box;
  }
  
  .dzu-previewStatusContainer {
    display: flex;
    align-items: center;
  }
  
  .dzu-previewFileName {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #f90;
  }

  .dzu-previewFileNameError {
    margin-right: 8px;
  }
  
  .dzu-previewImage {
    width: auto;
    max-height: 40px;
    max-width: 140px;
    border-radius: 4px;
  }
  
  .dzu-previewButton {
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0.9;
    margin: 0 0 2px 10px;
    background-color: #e64942;
  }
  
  .dzu-submitButtonContainer {
    margin: 24px 0;
    z-index: 1;
  }
  
  .dzu-submitButton {
    padding: 0 14px;
    min-height: 32px;
    background-color: #f90;
    border: none;
    border-radius: 4px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  
  .dzu-submitButton:disabled {
    background-color: #E6E6E6;
    color: #333333;
    cursor: unset;
  }
  