  .clip-header {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
}

.single_title {
    margin: 0;
    display: inline-block;
}

.previous {
    text-decoration: none;
    display: inline-block;
    padding: 2px 6px;
    background-color: #f1f1f1;
    color: black;
    border-radius: 50%;
    float: right;
    cursor: pointer;
}
  
@media (max-width: 767px) {
    .modal-wrapper {
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        box-sizing: border-box;
        background-color: black;
        margin-top: 56px;
        padding: 16px;
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 768px) {
    .modal-wrapper {
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: none; /* Disable scrolling when modal active */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        z-index: 1;
    }

    .modal-content {
        background-color: #1b1b1b;
        margin: 100px auto; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 700px; /* Could be more or less, depending on screen size */
    }
}

@media (min-width: 2561px) {
    .modal-content {
        width: 1200px; /* for the 4K boys */
    }
}